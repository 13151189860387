.wrapper {
  padding: 0 20px;

  p {
    color: var(--secondary-text);
  }
}

.photoWrapper {
  position: relative;
  max-width: 700px;
  min-width: 300px;
  width: 100%;
  height: 350px;
  padding: 5px 0;
}

.photo {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 1;
  left: 0;
  filter: saturate(0.5);
  background-color: var(--no-photo-color);
  border-radius: 8px;
  max-width: 700px;
  min-width: 300px;

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: 0.2s;

  &:hover {
    filter: saturate(1);

    cursor: pointer;
    margin: -10px 0 0 -5px;
  }

  &:hover + .photoBackground {
    margin: 5px -5px 0 0;
  }
}

.photoBackground {
  transition: 0.2s;
  height: 100%;
  border-radius: 8px;

  &.east_dundee {
    background-color: var(--east-dundee-color);
  }

  &.west_dundee {
    background-color: var(--west-dundee-color);
  }

  &.carpentersville {
    background-color: var(--carpentersville-color);
  }

  &.sleepy_hollow {
    background-color: var(--sleepy-hollow-color);
  }

  &.algonquin {
    background-color: var(--algonquin-color);
  }
}

.date {
  font-style: var(--font-italic-style);
}

.text {
  max-width: 555px;
}

.adWrapper {
  max-width: 750px;
  min-width: 350px;
  width: 100%;
  margin: 50px 0 150px 0;
  display: flex;
  justify-content: center;
  background-color: var(--secondary-color);
  border-bottom: 1px solid black;
  border-top: 1px solid black;
}

.ad {
  transform: scale(0.5);
}

@media only screen and (max-width: 650px) {
  .adWrapper {
    margin: 10px;
  }
}
