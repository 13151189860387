.modalWrapper {
  position: fixed;
  width: 100vw;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.8);
  right: 0;
  top: 0;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  padding: 40px;
  max-width: 700px;
  background-color: white;
  border-radius: 15px;
  border: 2px solid #009fdc;
}

.modalButton {
  margin: 10px;
}

.saveButton {
  color: #009fdc;
  border: 1px solid #009fdc;

  &:hover {
    background-color: #009fdc;
    color: white;
  }
}

.cancelButton {
  border: 1px solid #fd5858;
  color: #fd5858;

  &:hover {
    background-color: #fd5858;
    color: white;
  }
}
