:root {
  --font: "Newsreader";
  --font-italic-style: italic;
  --font-bold: 700;
  --font-extra-bold: 800;
  --wrapper-padding: 40px;
  --main-color: #f5f5f2;
  --secondary-color: #f0f0e8;
  --secondary-text: #555;
  --no-photo-color: #e8e8dc;
  --menu-color: #c3d9ea;
  --menu-secondary-color: #212e62;

  --focus-color: #e8f3ff;
  --focus-shadow-color: #d9ebfe;

  --east-dundee-color: #b8d1ec;
  --west-dundee-color: #e6bfa7;
  --carpentersville-color: #c9c5e5;
  --sleepy-hollow-color: #b7dce1;
  --algonquin-color: #b9d8ba;
}

code {
  font-family: var(--font), serif;
}

a {
  color: black;
  text-decoration: none;
}

body {
  font-family: var(--font), serif;
  margin: 0;
  box-sizing: border-box;
  background-color: var(--main-color);
}

h3 {
  margin: 0;
}

button {
  font-family: "Newsreader", serif;
  border: none;
  border-radius: 5px;
  background-color: white;
  min-width: 50px;

  transition: 0.5s;
  cursor: pointer;

  &:disabled {
    background-color: #d2d2d2;
    color: #a8a8a8;
    border: 1px solid #a8a8a8;
    cursor: not-allowed;
  }

  // &:enabled:not(:disabled) {
  //   &:hover,
  //   &:focus {
  //     font-size: 16px;
  //     box-shadow: 20px 0px 63px -20px rgba(0, 0, 0, 1);
  //   }
  // }
}
