.wrapper {
  position: relative;
  width: 100%;
  background-color: var(--main-color);
}

.header {
  margin: 0 40px;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
}

.underlines {
  border-bottom: 1px solid black;
  border-top: 1px solid #000;
  margin: 0 40px;
  height: 6px;
}

.menuIcon {
  outline: none;
  background-color: var(--main-color);
  transform: scale(0.5);
  justify-self: flex-start;
  transition: 0.5s;

  &:focus {
    transform: rotate(-90deg) scale(0.5);
  }

  &:hover {
    cursor: pointer;
    transform: rotate(-90deg) scale(0.5);
  }
}

.dundeeLogo {
  transform: scale(0.5);
}

.subscribeButton {
  justify-self: flex-end;
}
