.wrapper {
  padding: 0 20px;

  p {
    color: var(--secondary-text);
  }
}

.photoWrapper {
  position: relative;
  max-width: 200px;
  min-width: 160px;
  width: 100%;
  height: 200px;
}

.photo {
  filter: saturate(0.5);
  border-radius: 8px;
  background-color: var(--no-photo-color);
  position: absolute;
  top: 0;
  z-index: 1;
  left: 0;

  max-width: 200px;
  min-width: 160px;
  width: 100%;
  height: 100%;

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  transition: 0.2s;

  &:hover {
    cursor: pointer;
    filter: saturate(1);
    margin: -5px 0 0 -5px;
  }

  &:hover + .photoBackground {
    // cursor: pointer;
    // filter: saturate(1);

    margin: 5px -5px 0 0;
    // margin: 10px -10px 0 0;
  }
}

.photoBackground {
  transition: 0.2s;
  height: 100%;
  border-radius: 8px;

  &.east_dundee {
    background-color: var(--east-dundee-color);
  }

  &.west_dundee {
    background-color: var(--west-dundee-color);
  }

  &.carpentersville {
    background-color: var(--carpentersville-color);
  }

  &.sleepy_hollow {
    background-color: var(--sleepy-hollow-color);
  }

  &.algonquin {
    background-color: var(--algonquin-color);
  }
}

.date {
  font-style: var(--font-italic-style);
}
