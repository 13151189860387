.homeWrapper {
  max-width: 1400px;
  margin: 0 auto;
}

.wrapper {
  display: grid;
  padding: 20px 40px;
  grid-template-columns: 50% 50%;
}

.bottomBorder {
  height: 1px;
  background-color: black;
  margin: 30px 20px;

  &.last {
    display: none;
  }
}

@media only screen and (max-width: 850px) {
  .wrapper {
    grid-template-columns: 1fr;
    grid-template-rows: none;
    grid-gap: 40px;
  }
}
