.form {
  max-width: 700px;
  border: 1px solid black;
  margin: 0 auto;

  &.editing {
    border: 1px solid #009fdc;
  }
}

.wrapper {
  padding: 20px;
  display: grid;
  grid-gap: 20px;
  z-index: 1;
}
