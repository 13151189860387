.wrapper {
  max-width: 1400px;
  margin: 0 auto;
  outline: none;
}

.body {
  margin: 0 40px;
}

.underlines {
  border-bottom: 1px solid black;
  border-top: 1px solid #000;
  height: 6px;
}

.dundeeAdWrapper {
  display: flex;
  height: 100%;
  align-items: center;
  background-color: var(--secondary-color);
}

.dundeeAd {
  z-index: 4;
  height: 300px;
  max-width: 500px;
  min-width: 300px;
  background-color: var(--secondary-color);

  padding: 40px 0px;
}

.ad {
  transform: scale(0.5);
  transform-origin: top;
}

.introWrapper {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  grid-gap: 40px;
  justify-items: flex-end;
  border-bottom: 1px solid black;
  margin-bottom: 20px;
}

.introHeader {
  border-bottom: 1px solid black;
  font-size: 45px;

  margin: 0;
  padding: 20px 0px;
}

.introText {
  font-style: var(--font-italic-style);
  line-height: 30px;
  font-size: 20px;
  margin: 0;
  padding: 20px 0px;
}

.biosWrapper {
  padding-top: 20px;
  padding-bottom: 40px;

  p {
    line-height: 20px;
  }
}

.name {
  font-weight: var(--font-bold);
  margin: 0;
  font-size: 25px;
}

.roleTitle {
  margin: 0;
  font-size: 12px;
  padding-bottom: 20px;
}

.line {
  background-color: black;
  width: 2px;
}

@media only screen and (max-width: 850px) {
  .introWrapper {
    display: grid;
    grid-template-columns: 1fr;
  }

  .dundeeAdWrapper {
    max-width: none;
    width: 100%;
  }

  .dundeeAd {
    margin: 0 auto;
  }

  .biosWrapper {
    flex-direction: column !important;

    div {
      margin: 10px 0;
    }
  }

  .line {
    width: 100%;
    height: 1px;
  }
}
