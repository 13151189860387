.wrapper {
  outline: none;
  display: none;
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
  background-color: var(--menu-color);
  color: var(--menu-secondary-color);

  &.visible {
    display: block;
  }

  z-index: 999;
}

.body {
  display: grid;
  grid-template-columns: min-content auto min-content;
}

.imageWrapper {
  margin-top: 30px;
  transform-origin: top;
  transform: scale(0.5);
}

.xButton {
  transition: 0.2s;
  &:hover {
    cursor: pointer;
    transform: rotate(90deg);
  }
}

.close {
  text-transform: uppercase;

  transform: rotate(90deg);
  margin-top: 100px;
  font-size: 35px;

  &:hover {
    cursor: pointer;
  }
}

.underline {
  width: 250px;
  position: relative;
  z-index: 999;
  height: 3px;
  border-bottom: 1px solid var(--menu-secondary-color);
  border-top: 1px solid var(--menu-secondary-color);

  margin: 0 0 20px 12px;
}

.bulletPoint {
  background-size: 20px;
  transform: scale(0.5);
  height: 20px;
  width: 20px;
  background-size: cover;

  order: -1;
  transition: 0.2s;
  opacity: 0;
}

.largeBulletPoint {
  background-size: 20px;

  height: 20px;
  width: 20px;
  background-repeat: no-repeat;
  background-position: center;
  order: -1;
  transition: 0.2s;
  margin-left: -23px;
  opacity: 0;
}

.navWrapper {
  margin-top: 130px;

  .neighborhood {
    margin: 0 100px 30px 0;

    .townWrapper {
      &:hover {
        cursor: pointer;

        .bulletPoint {
          opacity: 1;
        }
      }
    }
  }
}

.navItem {
  &:hover {
    .largeBulletPoint {
      opacity: 1;
    }
  }

  &:focus {
    + .bulletPoint {
      height: 20px;
      width: 20px;
      opacity: 1;
      border-radius: 100%;
      background-color: var(--focus-color);
      box-shadow: 0px 0px 0px 80px var(--focus-color),
        0px 0px 0px 250px var(--focus-shadow-color);
    }
  }
}

.topics {
  max-height: 200px;
  flex-wrap: wrap;

  .topic {
    padding-left: 10px;
    &:hover {
      cursor: pointer;

      .bulletPoint {
        opacity: 1;
      }
    }
  }
}

.right {
  margin-top: 100px;
}

.smallButton {
  outline: none;
  background-color: transparent;
  color: var(--menu-secondary-color);
  margin: 5px 0;
  font-family: var(--font);
  font-weight: var(--font-bold);
  text-transform: capitalize;
  z-index: 999;

  &:hover {
    cursor: pointer;
  }

  &:focus {
    + .bulletPoint {
      height: 20px;
      width: 20px;
      opacity: 1;

      border-radius: 100%;
      background-color: var(--focus-color);
      box-shadow: 0px 0px 0px 80px var(--focus-color),
        0px 0px 0px 250px var(--focus-shadow-color);
    }
  }
}

.largeButton {
  outline: none;

  z-index: 999;
  margin: 5px 15px;
  background-color: transparent;
  color: var(--menu-secondary-color);
  font-size: 2em;
  padding: 21.4px 0;
  font-family: var(--font);
  font-weight: var(--font-bold);
  text-transform: capitalize;

  &:hover {
    cursor: pointer;
  }

  &:focus {
    + .largeBulletPoint {
      height: 20px;
      width: 20px;
      opacity: 1;
      border-radius: 100%;
      background-color: var(--focus-color);
      box-shadow: 0px 0px 0px 80px var(--focus-color),
        0px 0px 0px 250px var(--focus-shadow-color);
    }
  }
}

//TODO fix

// @media screen and (max-width: 850px) {
//   .subMenusWrapper {
//     flex-direction: column;
//   }

//   .topics {
//     flex-wrap: nowrap;
//     margin-bottom: 100px;
//   }

//   .imageWrapper {
//     &.right {
//       display: none;
//     }
//   }
// }
