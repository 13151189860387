.wrapper {
  display: none;

  &.open {
    background-color: rgba(195, 217, 234, 0.5);
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 999;
    display: flex;
    align-items: center;
  }
}

// --menu-color: #c3d9ea;
//   --menu-secondary-color: #212e62;

.body {
  background-color: var(--menu-color);
  border: 3px solid var(--menu-secondary-color);

  width: 40vw;
  margin: 0 auto;
  padding: 10px;
  border-radius: 30px;
}

.messageWrapper {
  color: var(-menu-secondary-color);
  padding: 50px;

  p,
  h1 {
    color: var(--menu-secondary-color);
  }
}

.closeButton {
  color: var(--menu-secondary-color);
  background-color: transparent;
  font-size: 35px;
  font-weight: bold;
}
