.wrapper {
  max-width: 1400px;
  margin: 0 auto;
  outline: none;
}

.body {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 40px;
}

.headerWrapper {
  padding: 20px 0;
  max-width: 800px;
  border-bottom: 1px solid black;
  margin: 0 auto;
}

.photoWrapper {
  max-width: 800px;
  min-width: 300px;
  width: 100%;
  height: 400px;
}

.photo {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.articleWrapper {
  max-width: 800px;
  margin: 0 auto;

  .storyText {
    margin: 0 0 30px 0;
    line-height: 20px;
    word-spacing: 2px;
  }
}

.author {
  margin-top: 20px;
  font-weight: var(--font-bold);
}

.date {
  margin-top: 20px;
  font-style: var(--font-italic-style);
}

@media only screen and (max-width: 850px) {
}
